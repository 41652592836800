import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${p => p.theme.color.white100};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow-y: auto;
`;

export const IndentedText = styled.div`
  white-space: pre-wrap; /* Preserve line breaks */
  text-align: left; /* Ensure left alignment */
  padding-left: 1em;
  
  /* Indent only for wrapped lines */
  & > * {
    text-indent: -.8em; /* Negative indent aligns with bullet */
    padding-left: 1em; /* Indentation for wrapped lines */
  }
  
  /* Ensuring that each bullet point behaves independently */
  & > *:not(:last-child) {
    margin-bottom: 0.5em; /* Space between bullet points if needed */
  }
`;