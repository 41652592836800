/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import CheckItem from 'components/atoms/CheckItem';
import CaptionGraphic from 'components/atoms/CaptionGraphic';
import Carousel from 'components/molecules/Carousel';
import Row from 'containers/Row';
import Footer from 'containers/Footer';
import Background from 'images/hero-bg.png';
import Tablet from 'images/tablet-no-bg.png';
import Technology from 'images/technology.png';
import Thrive from 'images/thrive.jpg';
import Meet from 'images/meet.jpg';
import HeroIllustration from 'images/hero-illustration.png';
import TeamIllustration from 'images/team.png';
import Chantal from 'images/chantal-round.png';
import Elizabeth from 'images/elizabeth-round.png';
import Paul from 'images/paul-round.png';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { getPWADisplayMode } from 'shared/functions/utils';
import DownloadModal from 'containers/Nav/DownloadModal';
import ConfirmDownloadModal from 'containers/Nav/ConfirmDownloadModal';
import { actions as filterActions } from 'data/filter/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterModal, selectFilters } from 'data/filter/selectors';
import { storeFolderlist } from 'data/database';

import {
  images,
  booksMessages,
  worldMessages,
  haitiMessages,
  deviceMessages,
  tabletMessages,
  technologyMessages,
  thriveMessages,
  meetMessages,
} from './constants';

import {
  HeroWrapper,
  IllustrationWrapper,
  BackgroundWrapper,
  FilterWrapper,
  TitleWrapper,
  ContentWrapper,
  ChecksWrapper,
  ButtonsWrapper,
  RowWrapper,
  FoldWrapper,
  Tile,
  TilesWrapper,
  Wrapper,
  DonateWrapper,
  Divider,
  TeamWrapper,
  Team,
  TeamColumns,
} from './wrappers';
import messages from './messages';

const Home = () => {
  const [showInstall, setShowInstall] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showConfirmDownload, setshowConfirmDownload] = useState(false);
  const [confirmDownloadMessage, setConfirmDownloadMessage] = useState(null);
  const [confirmDownloadResults, setConfirmDownloadResults] = useState(null);
  const filterModal = useSelector(selectFilterModal);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const filters = useSelector(selectFilters);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const navigate = useNavigate();
  const display = getPWADisplayMode();
  const isTablet = useMediaQuery('(max-width: 1370px)');

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstall(true);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  const installClick = async (e) => {
    setShowCheckboxes(true);
  };

  const confirmSelection = async (e) => {
    console.log(selectAll);
    setShowCheckboxes(false);

    const response = await fetch('https://notisis.life/api/get_books', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ filters: filters, selectAll })
    });
    const results = await response.json();
    console.log(results);
    const result = await storeFolderlist(results);
    console.log(result);

    let message = "Ready to install the app on your device and download ";
    const parts = [];
    for (const key in filters) {
      if (filters[key]) {
        if (filters[key].length > 0) {
          parts.push(filters[key].join(', '));
        }
      }
    }
    if (parts.length > 0) {
      message += parts.join(', ');
    }
    message += ` books :)`;
    message = message.trim();
                  
/*⁃ ${results.count} books in total :D

⁃ It will take about ${Math.round(results.count/60)} minutes if your internet is fast. 
  The larger the book selection and/or the slower the internet connection, the longer it will take.

⁃ Hit cancel to cancel download or change the selection of books.

⁃ Once the app is installed and the books are 
  downloaded, you will be able to use the app with
  or without the internet!

⁃ If you already have the app and would like to update, please uninstall the old version first [INSERT HYPERLINK HERE]. 

⁃ Please be aware that the app will not download on IOS devices. 
  This is not our fault, but due to anticompetitive behavior by Apple
  for which they are currently being sued by the US Government.

⁃ Depending on what device you're on you may have to submit 
  this form a second time (sorry, still working on fixing this issue :3)`;*/
    //message = message.trim();
    console.log(message);
    setConfirmDownloadMessage(message);
    setConfirmDownloadResults(results);
    setshowConfirmDownload(true);
  };

  const confirmDownload = async (e) => {
    setshowConfirmDownload(false);
    //if (!showConfirmDownload && deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        setDeferredPrompt(null);
        setShowInstall(false);
      }
    //}
  };
  
  return (
    <Wrapper>
      <HeroWrapper>
        <IllustrationWrapper src={HeroIllustration} />
        <BackgroundWrapper style={{ backgroundImage: `url(${Background})` }} />
        <FilterWrapper />
        <TitleWrapper>
          <Typography
            variant="h1"
            as="h1"
            label={<FormattedMessage {...messages.header} />}
          />
        </TitleWrapper>
        <ContentWrapper>
          <Typography
            variant="text-lg"
            as="p"
            label={<FormattedMessage {...messages.pitch} />}
          />
          <ButtonsWrapper>
            <Button
              type="button"
              onClick={() => navigate('library')}
              label={<FormattedMessage {...messages.explore} />}
            />
            {display === 'browser' && showInstall && (
              <Button
                type="button"
                variant="ghost"
                onClick={installClick}
                label={<FormattedMessage {...messages.install} />}
              />
            )}
          </ButtonsWrapper>
        </ContentWrapper>
        <FoldWrapper>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.tileHeader} />}
          />
          <TilesWrapper>
            <Tile color="red500">
              <Icon variant="bookstack" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileBooks} />}
              />
              <Divider opacity="0.2" />
              <ChecksWrapper>
                {booksMessages.map(msg => (
                  <CheckItem key={msg} icon="check-white" message={msg} />
                ))}
                <CheckItem key="resources" message="resources" />
              </ChecksWrapper>
            </Tile>
            <Tile color="blue500">
              <Icon variant="globe" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileWorld} />}
              />
              <Divider opacity="0.2" />
              <CheckItem key="writtenRecorded" message="writtenRecorded" />
              <br />
              <ChecksWrapper columns>
                {worldMessages.map(msg => (
                  <CheckItem
                    columns
                    key={msg}
                    icon="check-white"
                    message={msg}
                  />
                ))}
              </ChecksWrapper>
            </Tile>
            <Tile color="blue200">
              <Icon variant="haiti" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileHaiti} />}
              />
              <Divider opacity="0.2" />
              <ChecksWrapper>
                {haitiMessages.map(msg => (
                  <CheckItem key={msg} icon="check-red" message={msg} />
                ))}
              </ChecksWrapper>
            </Tile>
          </TilesWrapper>
        </FoldWrapper>
      </HeroWrapper>
      <DonateWrapper marginTop={40}>
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.donateHeader} />}
        />
        <Button
          variant="primary"
          iconLeft="donate-white"
          onClick={() =>
            window.open(
              'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
              '_blank',
            )
          }
          label={<FormattedMessage {...messages.donate} />}
        />
      </DonateWrapper>
      {showCheckboxes && (
        <DownloadModal
          isOpen={showCheckboxes}
          onClose={() => setShowCheckboxes(false)}
          filter={filterModal}
          isMobile={isMobile}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          confirmSelection={confirmSelection}
          updateSelectAll={() => setSelectAll(true)}
      />
      )}
      {showConfirmDownload && 
        <ConfirmDownloadModal
          isOpen={showConfirmDownload}
          onClose={() => setshowConfirmDownload(false)}
          message={confirmDownloadMessage}
          confirmDownload={confirmDownload}
          results={confirmDownloadResults}
          />
          }
      <Carousel variant="small" {...{ images }} />
      <Row color="blue100" imageBg="blue200" imageRight={Tablet}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.tabletHeader} />}
        />
        <CheckItem message="used" />
        <RowWrapper>
          {deviceMessages.map(msg => (
            <CheckItem key={msg} icon="check-red" message={msg} />
          ))}
        </RowWrapper>
        <br />
        <CheckItem message="downloading" />
        {tabletMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <RowWrapper>
          <Button
            type="button"
            onClick={() => navigate('library')}
            label={<FormattedMessage {...messages.explore} />}
          />
          <Button
            type="button"
            variant="ghost"
            label={<FormattedMessage {...messages.userGuide} />}
            disabled
          />
        </RowWrapper>
      </Row>
      <Row color="white100" imageLeft={Technology} imagePadding={77}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.technologyHeader} />}
        />
        <CheckItem message="noAccess" />
        {technologyMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <CheckItem message="openSource" />
      </Row>
      <Row color="blue100" imageRight={Thrive}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.thriveHeader} />}
        />
        <RowWrapper>
          {thriveMessages.slice(0, 3).map(item => (
            <CaptionGraphic
              key={item.msg}
              icon={item.icon}
              color={item.color}
              message={item.msg}
            />
          ))}
        </RowWrapper>
        <RowWrapper>
          {thriveMessages.slice(3, 6).map(item => (
            <CaptionGraphic
              key={item.msg}
              icon={item.icon}
              color={item.color}
              message={item.msg}
            />
          ))}
        </RowWrapper>
      </Row>
      <Row color="blue100" imageLeft={Meet}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.meetHeader} />}
        />
        <CheckItem message="nonprofit" />
        {meetMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <Button
          type="button"
          onClick={() => navigate('about-us')}
          label={<FormattedMessage {...messages.learn} />}
        />
      </Row>
      <TeamWrapper>
        <Team src={TeamIllustration} />
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.teamHeader} />}
        />
        <TeamColumns>
          <div>
            <img alt="Chantal" src={Chantal} />
            <Typography variant="h3" as="h3" label="Chantal Coutard" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamDirector} />}
            />
          </div>
          <div>
            <img alt="Elizabeth" src={Elizabeth} />
            <Typography variant="h3" as="h3" label="Elizabeth Wicks" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamCofounder} />}
            />
          </div>
          <div>
            <img alt="Paul" src={Paul} />
            <Typography variant="h3" as="h3" label="Paul Wicks" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamCofounder} />}
            />
          </div>
        </TeamColumns>
        <Button
          type="button"
          onClick={() => navigate('about-us')}
          label={<FormattedMessage {...messages.learn} />}
        />
      </TeamWrapper>
      <DonateWrapper marginTop={isTablet ? 60 : 120}>
        <Icon variant="donate-red" />
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.futureHeader} />}
        />
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.future} />}
        />

        <ButtonsWrapper>
          <Button
            variant="primary"
            iconLeft="donate-white"
            onClick={() =>
              window.open(
                'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
                '_blank',
              )
            }
            label={<FormattedMessage {...messages.donate} />}
          />
          {display === 'browser' && showInstall ? (
            <Button
              type="button"
              variant="primary"
              onClick={installClick}
              label={<FormattedMessage {...messages.install} />}
            />
          ) : (
            <Button
              type="button"
              onClick={() => navigate('library')}
              label={<FormattedMessage {...messages.explore} />}
            />
          )}
        </ButtonsWrapper>
      </DonateWrapper>

      <Footer />
    </Wrapper>
  );
};

export default Home;
