import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  text-align: left;
  margin: 0 auto;
  margin-top: 80px;

  @media ${p => p.theme.device.desktop} {
    margin-top: 120px;
  }
`;

export const HeaderWrapper = styled.div`
  height: 120px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 24px;
  background-image: ${p => `url('${p.src}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  h1 {
    color: ${p => p.theme.color.blue500};
  }

  @media ${p => p.theme.device.desktop} {
    margin: 120px auto 0px;
    height: 200px;
  }
`;

export const BodyWrapper = styled.div`
  width: 98%;

  @media ${p => p.theme.device.desktop} {
    width: 1080px;
  }
`;

export const RowWrapper = styled.section`
  width: ${p => p.width || 'calc(100% - 88px)'};
  height: ${p => p.height || 'auto'};
  padding: ${p => p.padding || 0};
  margin: ${p => p.margin || '40px auto'};
  position: relative;
  background-color: ${p => p.color && p.theme.color[p.color]};
  display: ${p => (p.flex && 'flex') || 'block'};
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  h2 {
    color: ${p => p.theme.color.blue500};
    font-weight: 600;
    margin-bottom: 24px;
  }
  h4 {
    margin-bottom: 16px;
    color: ${p => p.theme.color.red500};
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.2em;
  }
  p {
    color: ${p => p.theme.color.black200};
    font-weight: 300;
    line-height: 20px;
  }

  @media ${p => p.theme.device.desktop} {
    max-width: 1760px;
    margin: ${p => p.margin || '60px auto'};

    p {
      line-height: 24px;
    }
  }

  @media (max-width: 768px) { // Adjust the max-width value based on your needs
    flex-direction: column;
    align-items: stretch; // Ensure tiles stretch to fill the width
    width: 100%; // Full width for mobile screens
  }
`;

export const ColumnWrapper = styled.div`
  width: ${p =>
    p.width ? (p.width === '1225px' ? '1000px' : p.width) : '600px'};
  display: ${p => (p.flex ? 'flex' : 'block')};
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: ${p => (p.center ? 'center' : 'flex-start')};
  justify-content: center;
  margin: 0 auto;
  padding: ${p => p.padding || '0 0 20px 0'};
  h2 {
    color: ${p => p.theme.color.blue500};
    font-weight: 600;
    margin-bottom: 44px;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    @media ${p => p.theme.device.desktop} {
      font-size: 20px;
    }
  }
  h4 {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;

    @media ${p => p.theme.device.desktop} {
      font-size: 24px;
      letter-spacing: 0.05em;
      line-height: 36px;
    }
  }
  h5 {
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    color: ${p => p.theme.color.blue300};
    @media ${p => p.theme.device.desktop} {
      font-size: 18px;
    }
  }
  p {
    color: ${p => p.theme.color.black200};
    margin-bottom: 16px;
  }
  button {
    margin-top: 28px;
  }
  a {
    text-decoration: none;
  }

  @media ${p => p.theme.device.desktop} {
    width: ${p => (p.width ? p.width : '700px')};
    padding: ${p => p.padding || '0 0 40px 0'};
  }

  @media (max-width: 768px) { // Adjust the max-width value based on your needs
    flex-direction: column;
    align-items: stretch; // Ensure tiles stretch to fill the width
    width: 100%; // Full width for mobile screens
  }
`;

export const BackgroundWrapper = styled.div`
  width: calc(100% - 300px);
  height: 120%;
  background-color: ${p => p.theme.color[p.color] || p.theme.color.white100};
  position: absolute;
  left: -44px;
  top: -10%;
  z-index: -1;
`;

export const IllustrationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  > img {
    max-width: 96vw;
  }
`;

export const CenteredIcon = styled.div`
  width: 100px;
  margin: 0 auto;
  margin-bottom: ${p => p.bottom || 0};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenteredText = styled.div`
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${p => p.bottom || 0};
  text-align: left;

  > p {
    margin-bottom: 8px;
  }
`;

export const DividerVertical = styled.div`
  width: 0px;
  height: 100%;
  min-height: 400px;
  border: 1px solid
    ${p => (p.color ? p.theme.color[p.color] : p.theme.color.white100)};
  margin: 0 auto;
`;
